import React from 'react';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { DrupalEdit } from '../../responsive/molecules/DrupalEdit';
import { FooterMenuItems } from '../../responsive/organisms/FooterMenuItems';

/**
 * This component is named after "legal", but in fact it shows menu items from "footer" menu.
 */
export const LegalMenu = () => {
  const styleMenuContainer = css`
    margin: 40px 15px 0 15px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    ${theme.mq.tablet} {
      ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      margin: 0 7%;
      border-bottom: solid 1px #333333;
    }
  `;
  const styleMenuItem = css`
    margin-bottom: 18px;
    ${theme.mq.tablet} {
      margin-right: 30px;
      font-size: 12px;
      line-height: 1.17;
    }
    a,
    a:hover {
      color: #ffffff;
    }
  `;
  return (
    <div css={styleMenuContainer}>
      <DrupalEdit entityType="menu" entityId="footer" color="white" />
      <FooterMenuItems styleMenuItem={styleMenuItem} />
    </div>
  );
};
