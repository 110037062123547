import React from 'react';
import { css } from '@emotion/react';
import { LegalMenu } from './components/LegalMenu';
import ShareKit from '../ShareKit';
import { conf, t } from '../../domain/services/configService';
import { MenuLink } from '../MenuLink';
import { theme } from '../../domain/theme/theme';
import { DrupalEdit } from '../responsive/molecules/DrupalEdit';

export const Footer = () => {
  const styles = {
    container: css`
      background-color: #000;
      clear: both;
      padding: 0 15px 60px 15px;
      ${theme.font.family('regularWeb')};
      ${theme.mq.tablet} {
        padding: 0 15px 15px 15px;
      }
    `,
    main: css`
      display: flex;
      flex-direction: column;
      padding: 35px 0 0px 0;
      margin: 0 15px;
      border-bottom: solid 1px #999999;
      color: #ffffff;
      ${theme.mq.tablet} {
        flex-direction: row;
        padding: 60px 0 0;
        margin: 0;
        justify-content: space-evenly;
        border-bottom: unset;
      }
    `,
    h3: css`
      ${theme.font.family('boldWeb')};
      font-weight: unset;
      text-transform: uppercase;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: 3px;
      margin-bottom: 20px;
    `,
    link: `
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin-top: 20px;
    display: block;
    ${theme.mq.tablet} {
      font-size: 12px;
      line-height: 1.17;
      letter-spacing: 0.1px;
      margin-right: 10px;
    }
    a {
      &:link {
        color: #ffffff;
      }

      &:visited {
        color: #ffffff;
      }
    }
  `,
    block: css`
      margin-bottom: 50px;
      padding-right: 10px;
    `,
    menuSubscribe: css`
      text-align: left;
    `,
    button: css`
      display: block;
      margin-top: 20px;
      a {
        ${theme.font.family('boldWeb')};
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 3px;
        display: block;
        text-transform: uppercase;
        &:link {
          color: #ffffff;
        }

        &:visited {
          color: #ffffff;
        }
      }
      ${theme.mq.tablet} {
        margin-top: 20px;
        margin-right: 0;
        border: none;
        height: unset;
        padding: unset;
        width: unset;
        a {
          line-height: 1.17;
          letter-spacing: 0.1px;
          ${theme.font.family('regularWeb')};
          text-align: unset;
          text-transform: unset;
        }
      }
    `,
    menuCopyright: css`
      margin: 20px 15px 0 15px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.1px;
      color: #fff;
      ${theme.mq.tablet} {
        font-size: 10px;
        line-height: 1.8;
        letter-spacing: 3px;
        text-align: center;
        text-transform: uppercase;
      }
    `
  };

  return (
    <footer css={styles.container}>
      <div css={styles.main}>
        {conf.menus.menuFooterExplore?.links.length > 0 && (
          <div css={[styles.block]}>
            <h3 css={styles.h3}>
              {t('Explore')}
              <DrupalEdit entityType="menu" entityId="explore" color="white" />
            </h3>
            <ul>
              {conf.menus.menuFooterExplore.links.map(link => (
                <MenuLink style={styles.link} link={link} key={link.url.path} />
              ))}
            </ul>
          </div>
        )}

        {conf.menus.menuFooterAboutUs?.links.length > 0 && (
          <div css={[styles.block]}>
            <h3 css={[styles.h3]}>
              {t('About us')}
              <DrupalEdit entityType="menu" entityId="about-us" color="white" />
            </h3>
            <ul>
              {conf.menus.menuFooterAboutUs.links.map(link => (
                <MenuLink style={styles.link} link={link} key={link.url.path} />
              ))}
            </ul>
          </div>
        )}

        {conf.menus.menuFooterSubscribe.length > 0 && (
          <div css={[styles.block]}>
            <h3 css={[styles.h3]}>
              {t('Subscribe')}
              <DrupalEdit
                entityType="menu"
                entityId="subscribe"
                color="white"
              />
            </h3>
            <ul css={styles.menuSubscribe}>
              {conf.menus.menuFooterSubscribe.map(link => (
                <MenuLink
                  style={styles.button}
                  link={link}
                  key={link.url.path}
                />
              ))}
            </ul>
          </div>
        )}

        <div css={[styles.block]}>
          <h3 css={styles.h3}>{t('Follow us')}</h3>
          <ShareKit type="newsletter" color="white" />
        </div>
      </div>
      <LegalMenu />
      <div css={styles.menuCopyright}>
        {t(
          'Copyright © 1996-2015 National Geographic Society. Copyright © 2015-2016 National Geographic Partners, LLC. All rights reserved'
        ).replace('@year', new Date().getFullYear())}
      </div>
    </footer>
  );
};
